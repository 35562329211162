<template>
	<el-form ref="defForm" :model="defForm" :rules="rules" label-width="80px">
		<el-form-item label="账号" prop="userName">
			<el-input v-model="defForm.userName" :disabled="true"></el-input>
		</el-form-item>
		<el-form-item label="昵称" prop="nickName">
			<el-input v-model="defForm.nickName"></el-input>
		</el-form-item>
		<el-form-item size="large">
			<el-button type="primary" :loading="loading" @click="submitForm()">保存</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
	import token from "@/token";
	import {update,detail,upload} from '@/api/user'
	export default {
		name: "Info",
		data() {
			return {
				loading: false,
				defForm: {
					id: 0,
					roleId: 0,
					admin: 'normal',
					userName: '',
					nickName: '',
					passWord: '',
					updateUserID: token.getUser().id,
					updateUserName: token.getUser().manager_username
				},
				rules: {
					userName: [{
						required: true,
						message: '必填项不能为空'
					}],
				},
			}
		},
		created() {
			let userInfo = token.getUser();
			this.defForm.id = userInfo.id
			this.defForm.userName = userInfo.manager_username
			this.defForm.nickName = userInfo.manager_nickname
			this.defForm.passWord = userInfo.manager_password
		},
		methods: {
			submitForm() {
				this.$refs['defForm'].validate(valid => {
					if (valid) {
						this.loading = true
						update(this.defForm).then(data => {
							this.loading = false
							// this.$message.success("管理员["+this.defForm.userName+"]编辑成功!")
							detail(this.defForm.id).then(data => {
								console.log("详情",data)
								// 将用户的最新信息写入localStorage
								token.setUser(data.data)
								// 更新数据
								let userInfo = data.data
								this.defForm.id = userInfo.id
								this.defForm.userName = userInfo.manager_username
								this.defForm.nickName = userInfo.manager_nickname
								this.defForm.passWord = userInfo.manager_password
								// 刷新页面，更换头部昵称
								location.reload();
							}).catch(error => {
								this.loading = false
								this.$message.error(error)
							})
						}).catch(error => {
							this.loading = false
							this.$message.error(error)
						})
					}
				})
			},
			uploadImg(param) {
				const formData = new FormData();
				formData.append("file", param.file)
				upload(formData).then(data => {
					this.defForm.avatar = data.data
				}).catch(error => {
					this.$message.error(error)
				})
			},
			//上传之前的验证
			beforeAvatarUpload(file) {
				const isIMAGE = (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/png')
				const isLt2M = file.size / 1024 / 1024 < 2
				if (!isIMAGE) {
					this.$message.error('上传文件只能是图片格式!')
				}
				if (!isLt2M) {
					this.$message.error('上传文件大小不能超过 2MB!')
				}
				return isIMAGE && isLt2M
			},
		}
	}
</script>

<style scoped>
	.avatar-uploader {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		width: 178px;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
